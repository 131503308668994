import IconInstagram from "@/assets/instagramIcon";
import IconSpotify from "@/assets/spotifyIcon";
import { TeacherType } from "@/types/sanity";
import getSanityImage from "@/utils/sanity/getSanityImage";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import Image from "next/image";
import Link from "next/link";
import { PortableText } from "@portabletext/react";
interface Props {
  teacher: TeacherType;
  last?: boolean;
}

export default function InstructorCard({ teacher, last }: Props) {
  return (
    <div
      className={`mx-auto max-w-[600px] flex-1 border-b border-textGrey md:border-l md:border-r lg:mb-0 lg:border-b-0 lg:border-l  ${
        last ? "lg:border-r" : "lg:border-r-0"
      } `}
    >
      <div className="bg-bgLGrey p-3 text-[22px] font-[700] uppercase tracking-[2.2px] text-black ">
        <div className="flex ">
          <div>{teacher.name}</div>

          {teacher.instagramLink && (
            <div className="pl-2">
              <Link href={teacher.instagramLink}>
                <IconInstagram className="mr-8 h-full" />
              </Link>
            </div>
          )}
          {teacher.spotifyLink && (
            <div className="pl-2">
              <Link href={teacher.spotifyLink}>
                <IconSpotify className="mr-8 h-full" />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="border-b border-textGrey">
        <Image
          src={getSanityImage(teacher.image.asset).width(500).url()}
          alt={teacher.image.alt}
          width={500}
          height={450}
          className="w-full object-cover lg:h-[450px]"
        />
      </div>
      {/* <div className="border-b border-textGrey p-4 font-[700] uppercase">
        
        <div
          style={{
            listStyle: "disc",
            listStylePosition: "outside",
            paddingLeft: "1rem",
            lineHeight: "32px"
          }}
          dangerouslySetInnerHTML={{ __html: instructor.features }}
        ></div>
        <BlocksRenderer
          content={teacher.BulletPoints}
          blocks={{
            list: ({ children }) => (
              <ul className="list-outside list-disc pl-[1rem] ">{children}</ul>
            ),
          }}
        />
      </div> */}
      <div className="p-4">
        <PortableText
          value={teacher.description}
          components={{
            list: ({ children }) => (
              <ul className="list-outside list-disc pl-[1rem] ">{children}</ul>
            )
          }}
        />
        {/* <BlocksRenderer
          content={teacher.Bio}
          blocks={{
            list: ({ children }) => (
              <ul className="list-outside list-disc pl-[1rem] ">{children}</ul>
            )
          }}
        /> */}
      </div>
    </div>
  );
}
