"use client";

import InfoH2 from "../info/InfoH2";
import Button2 from "../ui/Button2/Button2";
import { Database } from "@/types_db";
import Image from "next/image";
import { RetreatVariant } from "@/types/sanity";
import getSanityImage from "@/utils/sanity/getSanityImage";
import { PortableText } from "@portabletext/react";

interface Props {
  handleCheckout: (retreatId: string, retreatVariant: string) => void;
  retreatVariant: RetreatVariant[];
  retreatId: string;
  variantId: string;
  soldOut: boolean;
}

export default function RetreatOptions({
  handleCheckout,
  retreatVariant,
  retreatId,
  variantId,
  soldOut
}: Props) {
  return (
    <div className="bg-bgLGrey">
      <div className="justify-center border-textGrey lg:flex lg:border-x">
        <div className="border-b lg:w-1/2 lg:border-r">
          <Image
            src={getSanityImage(retreatVariant[0].images[0].asset).url()}
            alt={retreatVariant[0].images[0].alt}
            width={0}
            height={0}
            sizes="100vw"
            className="h-1/2 w-full object-cover"
          />
          <Image
            src={getSanityImage(retreatVariant[0].images[1].asset).url()}
            alt={retreatVariant[0].images[1].alt}
            width={0}
            height={0}
            sizes="100vw"
            className="h-1/2 w-full object-cover"
          />
        </div>
        <div className="lg:w-1/2">
          <div className="flex h-full flex-col justify-between ">
            <div className="p-10">
              <InfoH2 style="dark">{retreatVariant[0].title}</InfoH2>
              <div className="uppercase tracking-[2.16px]">
                {retreatVariant[0].subHeading}
              </div>
            </div>

            <div className="px-10 text-[14px] lg:text-[16px]">
              <PortableText
                value={retreatVariant[0].description}
                components={{
                  list: ({ children }) => (
                    <ul className="list-outside list-disc pl-[1rem]">
                      {children}
                    </ul>
                  ),
                  block: {
                    normal: ({ children }) => (
                      <p className="mx-auto pb-4 text-left text-[16px] font-[500] tracking-[0.32px] text-bgGrey">
                        {children}
                      </p>
                    ),
                    h2: ({ children }) => (
                      <h2 className="pb-5 text-left text-[22px] font-[500] uppercase tracking-[13.44px] lg:text-[32px]">
                        {children}
                      </h2>
                    ),
                    h3: ({ children }) => (
                      <h3 className="text-[22px] font-[200] uppercase tracking-[3.78px] text-textGrey lg:text-[32px]">
                        {children}
                      </h3>
                    ),
                    h4: ({ children }) => (
                      <h4 className="pb-8 text-[20px] tracking-[2.7px] text-textGrey">
                        {children}
                      </h4>
                    ),
                    h6: ({ children }) => (
                      <h6 className="uppercase">{children}</h6>
                    )
                  }
                }}
              />
              {/* <BlocksRenderer
                content={retreatVariant.Description}
                blocks={{
                  // You can use the default components to set class names...
                  paragraph: ({ children }) => (
                    <p className="pb-4 font-[500] mx-auto text-left text-[16px] tracking-[0.32px] text-bgGrey">
                      {children}
                    </p>
                  ),
                  heading: ({ children, level }) => {
                    switch (level) {
                      case 6:
                        return <p className="uppercase">{children}</p>;

                      default:
                        return <div>{children}</div>;
                    }
                  },
                  list: ({ children }) => (
                    <ul className="list-outside list-disc pl-[1rem]">
                      {children}
                    </ul>
                  ),
                  // For links, you may want to use the component from your router or framework
                  // link: ({ children, url }) => <Link to={url}>{children}</Link>,
                }}
              /> */}
              {/* <p className="uppercase">Inclusions:</p>

              <ul className="list-outside list-disc pl-[1rem]">
                <li>9 Nights & 10 Days in Our Luxurious Villa</li>
                <li>Airport Transfers to and from the Retreat</li>
                <li>
                  Private Chef-Prepared, Locally-Sourced Breakfast, Lunch, and
                  Dinner for the duration
                </li>
                <li>Daily Kundalini Yoga and Meditation</li>
                <li>Tantric Arts Activations</li>
                <li>Nature Relaxation & Integration Time</li>
                <li>Guided Day Trip</li>
                <li>Complimentary Tea, Coffee, and Filtered Water</li>
                <li>Goodie Bag</li>
                <li>Access to our on-Site Massage and Spa Treatments**</li>
                <li>Yoga Mat Provided</li>
              </ul> */}
            </div>
            <div className="p-10">
              <div className="uppercase text-bgGrey">
                {retreatVariant[0].discountText &&
                  retreatVariant[0].discountText}
              </div>
              <div className="text-[48px] font-[200] tracking-[6.48px]">
                ${retreatVariant[0].price / 100}
              </div>

              <div className="uppercase">Price per person. Price in USD</div>
            </div>
            <div className="border-t border-bgGrey p-6 text-bgGrey">
              {soldOut ? (
                <div>Sold Out</div>
              ) : (
                <Button2
                  onClick={() => {
                    handleCheckout(retreatId, variantId);
                  }}
                  style="dark"
                  className="font-[600]"
                >
                  Book Now
                </Button2>
              )}
            </div>
            <div className=" border-t border-bgGrey p-6 text-[12px]">
              {retreatVariant[0].terms && (
                <PortableText value={retreatVariant[0].terms} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
