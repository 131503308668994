import Image from "next/image";
import { ReactElement, ReactNode } from "react";
import ScrollSlide from "../scrollSlide/ScrollSlide";

interface Props {
  children: ReactNode;
  bgSrc?: string;
  bgColor?: string;
  backgroundSize?: string;
  banner?: boolean;
}

export default function BgSection({
  children,
  bgSrc,
  bgColor,
  backgroundSize = "100vw",
  banner = false,
}: Props) {
  return (
    <>
      <div
        style={{
          background: `${bgSrc ? `url('${bgSrc}')` : "unset"}`,
          backgroundRepeat: "repeat",
          backgroundSize: backgroundSize,
          backgroundColor: bgColor ? bgColor : "unset",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* <div className="absolute left-[-50%] top-1/2 z-50 w-[200%] rotate-[15deg]">
          <ScrollSlide />
        </div>
        <div className="absolute left-[-50%] top-1/2 z-50 w-[200%] -rotate-45">
          <ScrollSlide />
        </div> */}
        {banner && (
          <div className="absolute top-20 z-10 max-h-full max-w-full overflow-hidden align-middle">
            <Image
              src="/retreats/limited-space-available-banner-6.png"
              alt="limited spaces available"
              width={0}
              height={0}
              sizes="100vw"
              className="max-h-full w-full max-w-full object-cover align-middle"
              unoptimized={true}
            />
          </div>
        )}

        <div className="relative z-20 mx-auto max-w-[1160px]">{children}</div>
      </div>
    </>
  );
}
