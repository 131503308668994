import { QuestionAnswerType } from "@/types/sanity";
import { QandA } from "@/types/strapi/components/qanda/interfaces/QandA";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { PortableText } from "@portabletext/react";

interface Props {
  data: QuestionAnswerType[];
  style?: "horizontal" | "vertical";
}

export default function Accordion({ data, style = "horizontal" }: Props) {
  let [open, setOpen] = useState(0);

  return (
    <div
      className={`${
        style === "vertical" ? "flex h-full flex-col" : "w-full lg:flex"
      }`}
    >
      <div
        className={` flex flex-col justify-evenly ${
          style === "vertical" ? "" : "lg:w-[40%]"
        }`}
      >
        {data.map((a, i) => {
          return (
            <div className="h-full" key={i}>
              <div
                onClick={() => setOpen(i)}
                className={`border-t border-textGrey ${
                  open === i ? "bg-white text-textGrey" : " text-textGrey"
                } ${
                  i === 0
                    ? ` ${style === "vertical" ? "" : ""} border-t-0 `
                    : ""
                } ${
                  i === data.length - 1 ? "border-b-0 " : "border-b-0"
                } z-50 flex h-full cursor-pointer items-center p-6 text-left font-[700] uppercase`}
              >
                {a.title}
              </div>
              <AnimatePresence initial={false}>
                {i === open && (
                  <motion.section
                    initial={{ height: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ type: "spring", duration: 1.4, bounce: 0 }}
                    className={`bg-bgGrey2 ${
                      style === "vertical" ? "" : "lg:hidden"
                    } border-b   ${
                      i === data.length - 1
                        ? "border-b-0 "
                        : "border-b border-textGrey"
                    } text-left text-[14px] lg:text-[16px]`}
                  >
                    <div className=" bg-bgGrey2 p-4">
                      <PortableText value={a.description} />
                      
                    </div>
                  </motion.section>
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </div>
      <div
        className={`hidden w-[60%] whitespace-normal border-l-2 p-10 text-left ${
          style === "vertical" ? "hidden" : "lg:block"
        }`}
      >
        <div className="flex h-full flex-col justify-evenly">
          <PortableText value={data[open].description} />
        </div>
      </div>
    </div>
  );
}
