import React from 'react'
import client from './sanity-client'
import imageUrlBuilder from '@sanity/image-url'
import { SanityReference } from 'sanity-codegen';

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder({projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string, dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string})

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
export default function getSanityImage(source: SanityReference<"image">) {
  return builder.image(source)
}