import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  src: SrcProps;
}

interface SrcProps {
  mp4: string;
  webm: string;
}

export default function VideoHero({ children, src }: Props) {
  return (
    <>
      <div className="h-screen">
        <video
          loop
          muted
          autoPlay
          playsInline
          preload="auto"
          width="100%"
          height="100%"
          className="h-full w-full overflow-hidden object-cover"
        >
          <source src={src.webm} type="video/webm" />
          <source src={src.mp4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute left-0 top-0 z-10 flex h-full h-screen w-full justify-center">
          {children}
        </div>
      </div>
    </>
  );
}
