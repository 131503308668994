import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import Image from "next/image";
import React from "react";
import "@splidejs/react-splide/css";

interface Props {
  images: ImageObject[];
}

interface ImageObject {
  url: string;
  alt: string;
}

export function ImageSlider({ images }: Props) {
  return (
    <div className="">
      <Splide
        aria-label="venue images"
        options={{
          type: "loop",
          drag: "free",
          // perPage: 3,
          autoWidth: true,
          pagination: false,
          autoScroll: {
            pauseOnHover: false,
            pauseOnFocus: false,
            rewind: false,
            speed: 1
          }
          // fixedHeight: "800px",
          // autoHeight: true
        }}
        extensions={{
          AutoScroll
        }}
      >
        {images.map((a, i) => {
          return (
            <SplideSlide key={i}>
              <Image
                src={a.url}
                alt={a.alt}
                width={1000}
                height={1000}
                quality={100}
                className="h-[500px] w-auto"
              />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}
