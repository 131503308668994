import InfoH2 from "./InfoH2";
import Image from "next/image";
import { ReactNode } from "react";

interface Props {
  imgSrc: string;
  imgAlt: string;
  children: ReactNode;
}

export default function InfoBlock({ imgSrc, imgAlt, children }: Props) {
  return (
    <div className="lg:flex bg-bgLGrey h-full ">
      <div className="lg:w-1/2 relative flex items-center lg:border-x border-b lg:border-b-0 border-textGrey">
        <Image
          src={imgSrc}
          height={0}
          width={0}
          sizes="100vw"
          alt={imgAlt}
          className="w-full h-auto"
        />
      </div>
      <div className="lg:w-1/2  flex flex-col justify-between lg:border-r border-textGrey">
        {children}
      </div>
    </div>
  );
}
