import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  size?: "large" | "small" | "xsmall";
  style?: "light" | "dark" | "gold" | "holo";
}

export default function TitleFullWidth({
  children,
  className,
  size = "large",
  style = "dark",
}: Props) {
  return (
    <div
      style={{
        background:
          style === "holo" ? "url('/retreats/holographic-digital.png')" : "",
      }}
      className={`w-full ${
        style === "gold"
          ? "bg-satnamGold"
          : style === "dark"
          ? "bg-unset"
          : style === "holo"
          ? "bg-unset"
          : "bg-bgLGrey text-[#4C4643]"
      }
    ${className ?? ""}`}
    >
      <div
        className={` text-center max-w-[1160px] mx-auto ${
          style === "gold"
            ? "bg-satnamGold"
            : style === "dark"
            ? "bg-unset"
            : style === "holo"
            ? "bg-unset"
            : "bg-bgLGrey text-[#4C4643]"
        }
      ${className ?? ""}`}
      >
        {children}
      </div>
    </div>
  );
}
