import { ReactElement, ReactNode } from "react";

interface Props {
  children: string | ReactNode;
  style?: "light" | "gold" | "dark";
}

export default function InfoH2({ children, style = "light" }: Props) {
  return (
    <h2
      className={`text-[28px] uppercase tracking-[3.78px] font-[300] ${
        style === "light"
          ? "text-[#F5F5F5]"
          : style === "dark"
          ? "text-bgGrey"
          : "text-satnamGold"
      }  pb-5`}
    >
      {children}
    </h2>
  );
}
