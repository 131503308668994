"use client";

import {
  motion,
  useMotionValueEvent,
  useScroll,
  useSpring,
} from "framer-motion";
import { useEffect, useState } from "react";

export default function ScrollSlide() {
  const { scrollY } = useScroll();

  const scrollYSpring = useSpring(scrollY);

  const [hookedYPostion, setHookedYPosition] = useState(0);
  useMotionValueEvent(scrollYSpring, "change", (latest) => {
    setHookedYPosition(latest);
  });

  return (
    <div className="relative h-12">
      <motion.div
        style={{
          background: "url('/retreats/limited-spaces-ticker.svg')",
          backgroundRepeat: "repeat-x",
          backgroundPositionY: "center",
          backgroundPositionX: hookedYPostion,
          backgroundColor: "transparent",
          color: "black",
        }}
        className="h-12 bg-no-repeat border-y z-20 absolute w-full"
      />
      <div
        className=" absolute top-0 w-full h-12"
        style={{
          background: "url('/retreats/holographic-digital.png')",
        }}
      ></div>
    </div>
  );
}
