"use client";
import { PortableText } from "@portabletext/react";
import { ImageSlider } from "./../../../components/ImageSlider";
import VideoHero from "@/components/hero/VideoHero";
import InfoBlock from "@/components/info/InfoBlock";
import ScrollSlide from "@/components/scrollSlide/ScrollSlide";
import BgSection from "@/components/section/BgSection";
import TitleFullWidth from "@/components/section/TitleFullWidth";
import Accordion from "@/components/ui/Accordion/Accordion";
import Button2 from "@/components/ui/Button2/Button2";
import { RetreatsWithVariationsAndAttendees } from "@/types/retreat_types";
import { getStripe } from "@/utils/stripe-client";

import { Session } from "@supabase/supabase-js";

import Image from "next/image";
import { useEffect, useState } from "react";
import InstructorCard from "@/components/instructorCard/InstructorCard";
import RetreatOptions from "@/components/retreats/RetreatOptions";
import Modal from "@/components/ui/Modal/Modal";
import Link from "next/link";
import CTA from "@/components/CTA";
import { RetreatsPage } from "@/types/strapi/api/retreats-page/content-types/retreats-page/retreats-page";
import { Retreats } from "@/types/sanity";
import { buildFileUrl, getFile, getFileAsset } from "@sanity/asset-utils";
import { getSanityFileUrl } from "@/utils/sanity/getSanityFileUrl";
import getSanityImage from "@/utils/sanity/getSanityImage";

// import Button from "@/components/ui/Button";
// import { getStripe } from "@/utils/stripe-client";

export default function Retreat({
  pageData2
}: {
  pageData2: {
    _type: "retreats";
  } & Retreats;
}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [soldOut, setSoldOut] = useState(false);

  useEffect(() => {
    //check attendee count
    async function getAttendees() {
      console.log("fetching attendees");
      const url = `/api/class/attendee/count?id=${pageData2._id}&variation=${pageData2.retreatVariants[0]._key}`;
      console.log("URL", url);
      const res = await fetch(url, {
        method: "GET"
      });
      if (!res.ok) {
        console.log("Error in data");
        setMessage("Error processing. Please try again later.");
        throw Error(res.statusText);
      }

      let response = await res.json();
      console.log(response.count.data);

      if (response.count.data >= pageData2.retreatVariants[0].spaces) {
        setSoldOut(true);
      }
    }
    getAttendees();
  }, [pageData2._id, pageData2.retreatVariants]);

  async function handleCheckout(retreatId: string, retreatVariant: string) {
    setLoading(true);
    const res = await fetch("/api/create-retreat-checkout-session", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "same-origin",
      body: JSON.stringify({
        eventId: 1,
        retreatId: retreatId,
        retreatVariant: retreatVariant,
        email: email,
        name: name
      })
    });
    setLoading(false);
    if (!res.ok) {
      console.log("Error in data");
      setMessage("Error processing. Please try again later.");
      throw Error(res.statusText);
    }

    let response = await res.json();
    console.log(response);
    const stripe = await getStripe();
    stripe?.redirectToCheckout({ sessionId: response.sessionId });
  }

  return (
    <div className="">
      <VideoHero
        src={{
          webm: getSanityFileUrl(pageData2.videoHero.video.videoWebm.asset),
          mp4: getSanityFileUrl(pageData2.videoHero.video.videoMp4.asset)
        }}
      >
        <div className="flex flex-col justify-around overflow-hidden text-center">
          {/* <div className="p-6">
            <div className="mb-10">
              
              <SatnamLogoFull className="text-[#ffffff] w-[100]" />
            </div>
            <div className="uppercase tracking-[10px] md:tracking-[19px] md:text-[23px] font-[300] text-[#FFFDFD]">
              presents
            </div>
          </div> */}
          {pageData2.videoHero.overlayLogo && (
            <h1 className="mx-auto flex w-screen max-w-[1160px] justify-center px-3 text-center">
              <Image
                src={`${getSanityImage(
                  pageData2.videoHero.overlayLogo.asset
                ).url()}`}
                alt={pageData2.videoHero.overlayLogo.alt}
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
              />
              {/* <BaliSvg /> */}
            </h1>
          )}

          <div>
            {/* <h1 className="text-satnamGold uppercase text-4xl tracking-[10px] font-[700] pb-10">
              Tantric Feminine Retreat
            </h1> */}

            <h2 className="max-w-[1160px] px-3 text-[14px]  font-[600] uppercase tracking-[4.2px] md:text-xl lg:font-[500] lg:tracking-[7.2px]">
              {pageData2.videoHero.subHeading && (
                <div className="text-white">
                  <PortableText value={pageData2.videoHero.subHeading} />
                </div>
              )}
            </h2>
          </div>
        </div>
      </VideoHero>

      <div className="border-y border-textGrey text-center text-[16px] uppercase tracking-[0.4px] lg:text-[16px]">
        <div className="mx-auto max-w-[1160px] border-textGrey p-6 lg:border-x">
          <div>
            <PortableText value={pageData2.infoBlock1.body} />
          </div>
        </div>
      </div>
      <BgSection bgColor="#E2DED9" backgroundSize="200px">
        <div>
          <InfoBlock
            imgSrc={getSanityImage(pageData2.eventInfoBlock1.image.asset).url()}
            imgAlt="The venue"
          >
            {" "}
            <div className="p-8 pb-4 pt-2 text-[16px] leading-[27px] tracking-[0.4px] text-[#4C4643] lg:p-10">
              <PortableText
                value={pageData2.eventInfoBlock1.body}
                components={{
                  list: ({ children }) => (
                    <ul className="list-outside list-disc pl-[1rem]">
                      {children}
                    </ul>
                  ),

                  block: {
                    p: ({ children }) => (
                      <p className="pb-4 pt-2 text-left text-[16px] leading-[27px] tracking-[0.4px]">
                        {children}
                      </p>
                    )
                  }
                }}
              />
              {/* <BlocksRenderer
                content={pageData.attributes.EventInfoBlock1.Text}
                blocks={{
                  // You can use the default components to set class names...
                  paragraph: ({ children }) => (
                    <p className="pb-4 pt-2 text-[16px] leading-[27px] tracking-[0.4px]">
                      {children}
                    </p>
                  ),
                  // heading: ({ children }) => <h3 className="text-neutral900">{children}</h3>,
                  // ...or point to a design system
                  list: ({ children }) => (
                    <ul className="list-outside list-disc pl-[1rem]">
                      {children}
                    </ul>
                  )
                  // For links, you may want to use the component from your router or framework
                  // link: ({ children, url }) => <Link to={url}>{children}</Link>,
                }}
              /> */}
              {/* <div className="text-[16px] font-[700] uppercase leading-[27px] tracking-[0.4px]">
                  Join Our 10-Day Transformational Healing Retreat and Embrace
                  Your Pure Authenticity
                </div>
                <div className="pb-2 uppercase"></div>
                <div className="leading-7">
                  <p className="pb-2 pt-6 font-[500] uppercase">
                    Experience our Powerful Offering:
                  </p>
                  <ul className="list-outside list-disc pl-[1rem]">
                    <li>Kundalini Yoga & Meditation</li>
                    <li>Feminine Tantric Arts</li>
                    <li>Frequency Activations</li>
                    <li>Ayurveda Techniques</li>
                    <li>Sacred Ceremonies</li>
                    <li>Somatic Movement</li>
                  </ul>
                </div>
                <div className="mt-6">More surprises to come…</div> */}
            </div>
            <div className="flex justify-around border-t border-textGrey">
              <div className="w-full border-r border-textGrey py-5 text-center">
                {soldOut ? (
                  <div>Sold Out</div>
                ) : (
                  <Button2
                    loading={loading}
                    onClick={() =>
                      handleCheckout(
                        pageData2._id,
                        pageData2.retreatVariants[0]._key
                      )
                    }
                    className="font-[600]"
                  >
                    Book now
                  </Button2>
                )}
              </div>
              <div className="w-full  py-5 text-center">
                <Button2 loading={loading} url="?subscribe=true">
                  Subscribe
                </Button2>
              </div>
            </div>
          </InfoBlock>
        </div>
      </BgSection>
      <div className="border-y border-textGrey">
        <TitleFullWidth style="dark">
          <div className="border-textGrey px-3 py-14 lg:border-x">
            <PortableText
              value={pageData2.titleSection1.body}
              components={{
                list: ({ children }) => (
                  <ul className="list-outside list-disc pl-[1rem]">
                    {children}
                  </ul>
                ),
                block: {
                  normal: ({ children }) => (
                    <p className="mx-auto max-w-[730px] pb-4 pt-2 text-center text-[20px] leading-[27px] tracking-[0.4px] text-textGrey">
                      {children}
                    </p>
                  ),
                  h3: ({ children }) => (
                    <h3 className="text-[22px] font-[200] uppercase tracking-[3.78px] text-textGrey lg:text-[32px]">
                      {children}
                    </h3>
                  ),
                  h4: ({ children }) => (
                    <h4 className="pb-8 text-[20px] tracking-[2.7px] text-textGrey">
                      {children}
                    </h4>
                  )
                }
              }}
            />
            {/* <BlocksRenderer
              content={pageData.attributes.TitleSection1.Text}
              blocks={{
                // You can use the default components to set class names...
                paragraph: ({ children }) => (
                  <p className="mx-auto max-w-[730px] pb-4 pt-2 text-center text-[20px] leading-[27px] tracking-[0.4px] text-textGrey">
                    {children}
                  </p>
                ),
                heading: ({ children, level }) => {
                  switch (level) {
                    case 3:
                      return (
                        <h3 className="text-[22px] font-[200] uppercase tracking-[3.78px] text-textGrey lg:text-[32px]">
                          {children}
                        </h3>
                      );
                    case 4:
                      return (
                        <h4 className="pb-8 text-[20px] tracking-[2.7px] text-textGrey">
                          {children}
                        </h4>
                      );

                    default:
                      return <div>{children}</div>;
                  }
                },
                list: ({ children }) => (
                  <ul className="list-outside list-disc pl-[1rem]">
                    {children}
                  </ul>
                )
                // For links, you may want to use the component from your router or framework
                // link: ({ children, url }) => <Link to={url}>{children}</Link>,
              }}
            /> */}
          </div>
          {/* <div className="py-14 lg:border-x">
            <h3 className="text-[22px] font-[500] uppercase tracking-[3.78px] lg:text-[32px]">
              Tantra <br />
            </h3>
            <h4 className="pb-8 text-[20px] tracking-[2.7px]">/ˈtantrə/</h4>
            <div className="mx-auto text-center text-[16px] tracking-[0.4px]">
              <div className="mx-auto max-w-[730px]">
                <p>
                  Noun. A spiritual practice that unites individuals with the
                  divine, through meditation, rituals, and the harnessing of life
                  force energy (Shakti) within and without.
                </p>
              </div>
            </div>
          </div> */}
        </TitleFullWidth>
      </div>
      {pageData2.displayLimitedSpaceSlider && <ScrollSlide />}
      <div className="border-b border-t border-bgGrey">
        <BgSection>
          <div className="max-w-[1160px] lg:mx-auto lg:flex">
            {pageData2.teachers.map((a, i) => {
              return (
                <InstructorCard
                  key={i}
                  teacher={a}
                  last={i === pageData2.teachers.length - 1}
                />
              );
            })}
          </div>
        </BgSection>
      </div>
      <div className="">
        <ImageSlider
          images={pageData2.imageSlider.map((a) => {
            return {
              url: getSanityImage(a.asset).url(),
              alt: a.alt
            };
          })}
        />
      </div>
      <TitleFullWidth
        className="border-bgGrey px-4 text-left lg:border-x lg:px-10"
        style="light"
      >
        <div className="py-6 ">
          <PortableText
            value={pageData2.titleSection2.body}
            components={{
              types: {},
              list: ({ children }) => (
                <ul className="list-outside list-disc pl-[1rem]">{children}</ul>
              ),

              block: {
                normal: ({ children }) => (
                  <p className="mx-auto pb-4 text-left text-[16px] font-[500] tracking-[0.32px]">
                    {children}
                  </p>
                ),
                h2: ({ children }) => (
                  <h2 className="pb-5 text-left text-[22px] font-[500] uppercase tracking-[8.44px] lg:text-[32px]">
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 className="text-[22px] font-[200] uppercase tracking-[3.78px] text-textGrey lg:text-[32px]">
                    {children}
                  </h3>
                ),
                h4: ({ children }) => (
                  <h4 className="pb-8 text-[20px] tracking-[2.7px] text-textGrey">
                    {children}
                  </h4>
                )
              }
            }}
          />
          {/* <BlocksRenderer
            content={pageData.attributes.TitleSection2.Text}
            blocks={{
              // You can use the default components to set class names...
              paragraph: ({ children }) => (
                <p className="mx-auto pb-4 text-left text-[16px] font-[500] tracking-[0.32px]">
                  {children}
                </p>
              ),
              heading: ({ children, level }) => {
                switch (level) {
                  case 2:
                    return (
                      <h2 className="pb-5 text-left text-[22px] font-[500] uppercase tracking-[13.44px] lg:text-[32px]">
                        {children}
                      </h2>
                    );

                  default:
                    return <div>{children}</div>;
                }
              },
              list: ({ children }) => (
                <ul className="list-outside list-disc pl-[1rem]">{children}</ul>
              )
              // For links, you may want to use the component from your router or framework
              // link: ({ children, url }) => <Link to={url}>{children}</Link>,
            }}
          /> */}
        </div>
      </TitleFullWidth>
      {/* <div
          onClick={() => setReadMore(true)}
          className={`border-t border-bgGrey bg-bgLGrey p-8 uppercase text-bgGrey lg:hidden ${
            readMore ? "hidden" : ""
          }`}
        >
          Read More
        </div> */}
      <div className="border-y border-textGrey">
        <div className="mx-auto max-w-[1160px] uppercase lg:flex ">
          <div className="hidden h-full flex-1 border-t border-textGrey py-10 text-center lg:mb-0 lg:block lg:border-l lg:border-t-0">
            {soldOut ? (
              <div>Sold Out</div>
            ) : (
              <Button2
                style="dark"
                onClick={() =>
                  handleCheckout(
                    pageData2._id,
                    pageData2.retreatVariants[0]._key
                  )
                }
                loading={loading}
                className="font-[600]"
              >
                Book now
              </Button2>
            )}
          </div>
          <div className="h-full flex-1  border-textGrey py-10 text-center lg:mb-0 lg:hidden lg:border-l lg:border-t-0 lg:bg-inherit">
            {soldOut ? (
              <div>Sold Out</div>
            ) : (
              <Button2
                style="dark"
                onClick={() =>
                  handleCheckout(
                    pageData2._id,
                    pageData2.retreatVariants[0]._key
                  )
                }
                loading={loading}
                className="font-[600]"
              >
                Book now
              </Button2>
            )}
          </div>
          <div className="flex-1 border-t border-textGrey py-10 text-center lg:mb-0 lg:border-l lg:border-t-0">
            <Button2 style="dark" url="?subscribe=true" loading={loading}>
              Subscribe
            </Button2>
          </div>
          <div className="flex-1 border-t border-textGrey py-10 text-center lg:border-l lg:border-r lg:border-t-0">
            <Button2 style="dark" url="?app-link=true" loading={loading}>
              Download our timer app
            </Button2>
          </div>
        </div>
      </div>
      {/* <div className="border-y-2">
          <div className="lg:flex max-w-[1160px] mx-auto uppercase ">
            <div className="flex-1 text-center mb-6 lg:mb-0 border-l py-10 h-full">
              <Button2 style="light" onClick={() => setShowModal("contact")}>
                Contact Us
              </Button2>
            </div>
            <div className="flex-1 text-center mb-6 border-l py-10 lg:mb-0">
              <Button2 style="light" onClick={() => setShowModal("subscribe")}>
                Subscribe to our Newsletter
              </Button2>
            </div>
            <div className="flex-1 text-center border-l py-10 border-r">
              <Button2 style="light" target="_blank" url="/terms-and-conditions">
                view T&apos;s&C&apos;s
              </Button2>
            </div>
          </div>
        </div> */}
      <div className="border-bgGrey lg:border-b">
        <TitleFullWidth style="holo" className="border-bgGrey">
          {pageData2.titleSection3 && (
            <div className="h-full border-b border-textGrey px-4 py-10 text-[14px] font-[700] uppercase tracking-[3.3px] lg:border-x lg:border-b-0 lg:text-[20px]">
              <PortableText value={pageData2.titleSection3.body} />
              {/* <BlocksRenderer
                content={pageData.attributes.TitleSection3.Text}
              /> */}
            </div>
          )}
        </TitleFullWidth>
      </div>
      <div>
        <BgSection banner backgroundSize="200px">
          <RetreatOptions
            retreatVariant={pageData2.retreatVariants}
            handleCheckout={handleCheckout}
            retreatId={pageData2._id}
            variantId={pageData2.retreatVariants[0]._key} //this is the number in the array for when there are multiple variants
            soldOut={soldOut}
          />
        </BgSection>
      </div>
      <div className="border-y border-bgGrey">
        <TitleFullWidth style="light">
          <div className="border-textGrey px-5 py-10 text-[16px] font-[500] uppercase tracking-[0.72px] lg:border-x">
            <PortableText value={pageData2.titleSection4.body} />
          </div>
        </TitleFullWidth>
      </div>
      {pageData2.fullPageImage && (
        <div className="border-b border-bgGrey">
          <Image
            src={getSanityImage(pageData2.fullPageImage.asset).url()}
            width={0}
            height={0}
            sizes="100vw"
            alt={pageData2.fullPageImage.alt}
            className="h-auto min-h-[400px] w-full object-cover"
          />
        </div>
      )}

      <TitleFullWidth style="light">
        <div className="border-textGrey px-8 py-10 text-left lg:border-x lg:px-0 lg:text-center">
          <PortableText
            value={pageData2.callToAction.body}
            components={{
              list: ({ children }) => (
                <ul className="list-outside list-disc pl-[1rem]">{children}</ul>
              ),
              block: {
                normal: ({ children }) => (
                  <div className="mx-auto text-[16px] lg:max-w-[730px] lg:text-center lg:tracking-[2.4px]">
                    <p className="lg:leading-[43px]">{children}</p>
                  </div>
                ),
                h2: ({ children }) => (
                  <h2 className="pb-8 text-[22px] font-[500] uppercase tracking-[6.44px] lg:text-[32px] lg:tracking-[13.44px]">
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 className="text-[22px] font-[200] uppercase tracking-[3.78px] text-textGrey lg:text-[32px]">
                    {children}
                  </h3>
                ),
                h4: ({ children }) => (
                  <h4 className="pb-8 text-[20px] tracking-[2.7px] text-textGrey">
                    {children}
                  </h4>
                )
              }
            }}
          />

          {/* <BlocksRenderer
            content={pageData.attributes.CallToAction.Text}
            blocks={{
              // You can use the default components to set class names...
              paragraph: ({ children }) => (
                <div className="mx-auto text-[16px] lg:max-w-[730px] lg:text-center lg:tracking-[2.4px]">
                  <p className="lg:leading-[43px]">{children}</p>
                </div>
              ),
              heading: ({ children, level }) => {
                switch (level) {
                  case 2:
                    return (
                      <h2 className="pb-8 text-[22px] font-[500] uppercase tracking-[6.44px] lg:text-[32px] lg:tracking-[13.44px]">
                        {children}
                      </h2>
                    );

                  default:
                    return <div>{children}</div>;
                }
              },
              list: ({ children }) => (
                <ul className="list-outside list-disc pl-[1rem]">{children}</ul>
              )
              // For links, you may want to use the component from your router or framework
              // link: ({ children, url }) => <Link to={url}>{children}</Link>,
            }}
          /> */}

          <div className="mx-auto text-[16px] lg:text-center lg:tracking-[2.4px]">
            <div className="hidden lg:block ">
              <Button2
                className="pb-5 pt-10 font-[600]"
                style="dark"
                onClick={() =>
                  handleCheckout(
                    pageData2._id,
                    pageData2.retreatVariants[0]._key
                  )
                }
                loading={loading}
              >
                {pageData2.callToAction.buttonText}
              </Button2>
            </div>
          </div>
        </div>
      </TitleFullWidth>
      <div className="border-y border-textGrey">
        <div className="mx-auto max-w-[1160px] uppercase lg:flex ">
          <div className="h-full flex-1   border-textGrey py-10 text-center lg:mb-0 lg:hidden lg:border-l lg:border-t-0 lg:bg-inherit">
            {soldOut ? (
              <div>Sold Out</div>
            ) : (
              <Button2
                style="dark"
                onClick={() =>
                  handleCheckout(
                    pageData2._id,
                    pageData2.retreatVariants[0]._key
                  )
                }
                loading={loading}
                className="font-[600]"
              >
                Book now
              </Button2>
            )}
          </div>
          <div className="flex-1 border-t border-textGrey py-10 text-center lg:mb-0 lg:border-l lg:border-t-0">
            <Button2 style="dark" url="?subscribe=true" loading={loading}>
              Subscribe
            </Button2>
          </div>
          <div className="flex-1 border-t border-textGrey py-10 text-center lg:border-l lg:border-r lg:border-t-0">
            <Button2 style="dark" url="?contact=true" loading={loading}>
              Contact Us
            </Button2>
          </div>
        </div>
      </div>
      {/* <div className="bg-satnamGold lg:bg-inherit lg:hidden">
          <Button2
            className="pt-10 pb-5"
            style="dark"
            onClick={() => handleCheckout(1)}
          >
            Book Now
          </Button2>
        </div> */}

      <BgSection>
        <div className="flex flex-col items-center justify-center border-textGrey text-center lg:border-x">
          <h2 className="w-full border-b border-textGrey py-6 text-[22px] uppercase tracking-[3.78px] lg:text-[28px]">
            Answers to your questions
          </h2>
          <div className="max-w-[1160px]  text-center">
            <div>
              <Accordion style="vertical" data={pageData2.questions} />
            </div>
          </div>
        </div>
      </BgSection>
    </div>
  );
}
